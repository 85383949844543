const PageHeader = ({ title, description, body }) => (
  <>
    <p className="pageDescription">{description}</p>
    <h3 className="pageTitle">{title}</h3>
    <h4 className="pageBody">
      {body}
    </h4>
  </>
);

export default PageHeader;
