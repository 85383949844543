import projectData from "./projectsData";
import Project from "../../components/Project";
import PageHeader from "../../components/PageHeader";

const Portfolio = () => {
  const ProjectList = () =>
    projectData.map((project, i) => (
      <Project
        key={i}
        id={project.id}
        title={project.title}
        technologies={project.technologies}
        image={project.image}
        color={project.bgcolor}
        github={project.github}
        deployed={project.deployed}
        description={project.description}
      />
    ));

  return (
    <section className="portfolio">
      <PageHeader
        className="portfolio"
        title="Projects"
        body="Although the majority of my work is confidential and can be found within the Polytechnic Organization's private GitHub due to evaluation requirements, I have acquired a wealth of experience in constructing robust APIs, scalable web applications, and software development. I've also worked on AI model training and network system configuration, and contributed to various exciting robotics projects. Please note that this site may not be updated frequently due to my ongoing commitments. For a detailed insight into my skills and experiences, please refer to my CV."
        style={{
          color: "red",
          fontSize: "8vh",
          marginBottom: "2vh",
        }}
      />
      <p style={{ color: "white", fontSize: "1.3vh", margin: "2vh" }}>
        At present, I'm involved in several ongoing projects, including an
        exciting path of study project for Otago Polytechnic. To better
        understand my skills and experiences, I recommend reviewing my CV under
        Contact Page.
      </p>
      <div className="row">
        <ProjectList />
      </div>
    </section>
  );
};

export default Portfolio;
