import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Dani Ghauri",
    location: "Dunedin, NZ",
    email: "durremuhammadg@gmail.com",
    availability: "Open for work",
    brand:
      "Third year IT Student at Otago Polytechnic | Pursuing Bachelor of Information Technologies| Eager to Innovate and Impact the Tech Industry | Always Learning, Always Growing | Let's Connect and Shape the Future Together",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
    </>
  );
}

export default App;
