const AboutMe = () => {
  const progressBarStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    flexDirection: "row",
    marginRight: "20px",
  };

  const progressBarTextStyle = {
    position: "absolute",
    right: "5px",
    top: "50%",
    transform: "translateY(-50%)",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "bold",
  };
  const progressBarFillStyle = (percentage) => ({
    width: `${percentage}%`,
    height: "8px",
    background: "linear-gradient(to right, #FFD200, #FF4C29)",
  });

  const technicalSkills = [
    { skill: "React, Next.js, React Native", percentage: 95 },
    { skill: "RESTful API development", percentage: 90 },
    { skill: "Node.js, Golang, Strapi", percentage: 85 },
    { skill: "C++, C#, Python, Golang", percentage: 55 },
    { skill: "API data handling", percentage: 80 },
    { skill: "HTML, CSS, JavaScript", percentage: 90 },
    { skill: "Git", percentage: 95 },
    { skill: "Agile, Scrum, Kanban", percentage: 90 },
    { skill: "DevOps", percentage: 70 },
    { skill: "AI models, libraries", percentage: 65 },
    { skill: "Feature selection, AI tuning", percentage: 75 },
    { skill: "SQL, NoSQL databases", percentage: 75 },
    { skill: "OpenCV, image processing", percentage: 60 },
    { skill: "Facial recognition", percentage: 70 },
    { skill: "Networking configuration", percentage: 60 },
    { skill: "Networking protocols", percentage: 55 },
    { skill: "Bash scripting", percentage: 55 },
    { skill: "Raspberry Pi projects", percentage: 50 },
    { skill: "Linux experience", percentage: 60 },
    { skill: "Python libraries", percentage: 50 },
    { skill: "Arduino, debugging", percentage: 85 },
    { skill: "Embedded systems", percentage: 95 },
    { skill: "Unit, integration, testing", percentage: 60 },
  ];

  return (
    <div
      style={{
        color: "#FFFFFF",
        fontFamily: "Roboto, Arial, sans-serif",
        padding: "30px",
        lineHeight: "1.6",
        borderRadius: "10px",
        maxWidth: "900px",
        background:
          "linear-gradient(to bottom, #000000, #0e0d0e, #181617, #201d1f, #282527, #2e2b2e, #343135, #39373c, #3e3d43, #43444a, #484a52, #4d5159)",
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h1
        style={{
          fontSize: "32px",
          borderBottom: "2px solid #FFFFFF",
          paddingBottom: "10px",
          marginBottom: "20px",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
        }}
      >
        About
      </h1>
      <p style={{ fontSize: "20px", marginBottom: "10px" }}>
        I'm a final year student with a deep passion for backend development and
        problem-solving. I specialize in full-stack development and value
        continuous learning.
        <br />
        My multicultural background and friendly personality foster effective
        collaboration. I excel in solving complex problems with tight deadlines.
        Expected degree completion: November 2023. Actively seeking full-time
        roles in IT, with a focus on backend development.
      </p>
      <p>More about me:</p>
      <p style={{ fontSize: "20px", marginBottom: "10px" }}>
        <li>Born in Pakistan, now in Dunedin, New Zealand.</li>
        <li>Passionate about coding, design, and learning.</li>
        <li>Enthusiastic about cricket and badminton.</li>
        <li>
          Proven success in both self-directed and team-based academic
          environments.
        </li>
        <li>
          Looking for challenging opportunities to further enhance my skills.
        </li>
      </p>

      <h2
        style={{
          fontSize: "22px",
          borderBottom: "1px solid #FFFFFF",
          paddingBottom: "10px",
          marginBottom: "20px",
          color: "#FFFFFF",
        }}
      >
        Certifications
      </h2>
      <li
        style={{
          fontSize: "18px",
          marginBottom: "10px",
          lineHeight: "1.6",
        }}
      >
        Upper-Intermediate English Business, University of Polytechnic the
        València, Spain, EDX (2020)
        <li>Cisco IT Essentials Certificate, Otago Polytechnic (2021)</li>
        <li>Letter of Merit IT Essentials, Cisco (2021)</li>
      </li>

      <h2
        style={{
          fontSize: "22px",
          borderBottom: "1px solid #FFFFFF",
          paddingBottom: "10px",
          marginBottom: "20px",
          color: "#FFFFFF",
        }}
      >
        Skills
      </h2>
      <p
        style={{
          fontSize: "18px",
          marginBottom: "10px",
          lineHeight: "1.6",
        }}
      >
        <li>Effective communication and active listening skills</li>

        <li>Resourcefulness and creative problem-solving abilities</li>

        <li>Strong work ethic and dedication to tasks</li>

        <li>Team player and leadership qualities when needed</li>

        <li>Flexibility and adaptability to new ideas and challenges</li>

        <li>
          Excellent time management and ability to handle multiple projects
          simultaneously
        </li>
      </p>

      <h2
        style={{
          fontSize: "22px",
          borderBottom: "1px solid #FFFFFF",
          paddingBottom: "10px",
          marginBottom: "20px",
          color: "#FFFFFF",
        }}
      >
        Technical Skills
      </h2>
      <ul
        style={{
          fontSize: "22px",
          borderBottom: "1px solid #FFFFFF",
          paddingBottom: "10px",
          marginBottom: "20px",
          color: "#FFFFFF",
        }}
      >
        {technicalSkills.map(({ skill, percentage }) => (
          <li style={progressBarStyle} key={skill}>
            <div style={{ width: "60%", marginRight: "10px" }}>{skill}</div>
            <div style={{ width: "40%", position: "relative" }}>
              <div style={progressBarFillStyle(percentage)}></div>
              <div style={{ ...progressBarTextStyle }}>{percentage}%</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AboutMe;
